export type HeaderProps = {
  active: string,
  offset: number
}

export const navigationLinks = [
  {
    id: 1,
    title: "Home",
    url: "/"
  },
  {
    id: 2,
    title: "Work",
    url: "/work"
  },
  {
    id: 3,
    title: "About us",
    url: "/about"
  },
  {
    id: 4,
    title: "Services",
    url: "/services"
  },
  {
    id: 5,
    title: "Team",
    url: "/team"
  },
  {
    id: 6,
    title: "Contact",
    url: "/contact"
  }
];

