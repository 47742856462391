import React, { useCallback, useEffect, useState, Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import logo from './assets/logo.svg';
import checkmark from './assets/checkmark.svg';
import arrow from './assets/arrow.svg';
import menu from './assets/menu-outline.svg';
import customers from './assets/partner-logos.png';
import customersMobile from './assets/partner-logos-mobile.png';

import { motion } from "framer-motion"
import { navigationLinks } from "./Header"
import { ProcessCards } from "./ProcessCards"
import { ProjectDetails, ProjectDetailsProps } from "./ProjectDetails"
import { PositionDetails, PositionDetailsProps } from "./PositionDetails"
import { Impressum } from "./Impressum"

import { DataPrivacy } from "./DataPrivacy"

import { Projects } from "./data/projects"

import { TrackPageView, TrackEvent } from "./Matomo"

import { CookieBanner } from "./CookieBanner";
import { Testimonials } from "./Testimonials"

import { Footer } from "./Footer"

import './App.scss';

import Typewriter from 'typewriter-effect';

import services_circle from "./assets/services/circle.svg";
import services_brand from "./assets/services/brand.svg";
import services_development from "./assets/services/development.svg";
import services_ecommerce from "./assets/services/e-commerce.svg";
import services_marketing from "./assets/services/marketing.svg";
import services_strategy from "./assets/services/strategy.svg";
import services_ux from "./assets/services/ux.svg";
import services_design from "./assets/services/design.svg";
import services_curve from "./assets/services/curve.svg";
import services_curve_mobile from "./assets/services/curve-mobile.svg";

import dictionary from './assets/languages.json';

import globe_icon from './assets/globe.svg';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

import useFetch from './hooks/useFetch';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Pagination } from "swiper";

function App() {
  const [isMobileNavigationOpen, openMobileNavigation] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
  const [impressumOpened, setIsImpressumOpened] = useState(false);
  const [dataPrivacyOpened, setIsDataPrivacyOpened] = useState(false);
  const [cookieBannerVisible, setCookieBannerVisibility] = useState("none" as "none" | "allowed" | "not_allowed");
  const [projectsData, setProjectsData] = useState([] as ProjectDetailsProps[]);

  var userBrowserLanguage = navigator.language.split("-")[0]; 
  const [selectedLanguage, setLanguage] = useState(localStorage.getItem('language') as "de" | "en" || (userBrowserLanguage === "de" ? "de" : "en"));

  localStorage.setItem('language', selectedLanguage);

  useEffect(()=>{
      const setting = localStorage.getItem('cookieBanner');
      if (setting)
        setCookieBannerVisibility(localStorage.getItem('cookieBanner') as "none" | "allowed" | "not_allowed");
  }, []);

  useEffect(()=>{
    localStorage.setItem('cookieBanner', cookieBannerVisible);

    // delete cookies if user denies allowance
    if(cookieBannerVisible === "not_allowed") {
        var allCookies = document.cookie.split(';');
        for (var i = 0; i < allCookies.length; i++) {
          document.cookie = allCookies[i] + "=;expires="+ new Date(0).toUTCString();
        }
    }

  }, [cookieBannerVisible]);


  /* Init project data */
  const getProjectsData = useCallback(async () => {
    const data = await Projects.initProjects();
    setProjectsData(data);
    //console.log("setProjectsData")
  }, [])
  
  useEffect(()=>{
      getProjectsData()
        .catch(console.error);
  }, [])

  /* Open data privacy */
  useEffect(()=>{
      if(window.location.pathname === "/privacy") {
        setIsDataPrivacyOpened(true);
      }
    }, [])

  /* Open imprint */
  useEffect(()=>{
    if(window.location.pathname === "/imprint") {
      setIsImpressumOpened(true);
    }
  }, [])


  const handleClickOnNavItem = (url: string, scrollToId?: string) => {
    setMobileMenu(false);
    setCurrentUrl(url);
    if(!scrollToId) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        document.getElementById(scrollToId)!.scrollIntoView({
          behavior: 'smooth'
        });
      }, 500);
    }

  }

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, []);

  function setMobileMenu(open: boolean) {
    openMobileNavigation(open);
    document.body.style.overflow = open ? "hidden" : "scroll"
  }


  function openImpressum() {
    setIsImpressumOpened(true);
    document.body.style.overflow = "hidden"
  }

  function closeImpressum() {
    setIsImpressumOpened(false);
    document.body.style.overflow = "scroll"
  }

  function openDataPrivacy() {
    setIsDataPrivacyOpened(true);
    document.body.style.overflow = "hidden"
  }

  function closeDataPrivacy() {
    setIsDataPrivacyOpened(false);
    document.body.style.overflow = "scroll"
  }


  return (
    <Router>
      <div className="App">
        {
          cookieBannerVisible === "none" ? <CookieBanner 
                                              selectedLanguage={selectedLanguage} 
                                              dictionary={dictionary} 
                                              onDeny={() => setCookieBannerVisibility("not_allowed")}
                                              onAccept={() => setCookieBannerVisibility("allowed")}
                                              /> : null
        }
      <motion.div
            initial={false}
            animate={{ opacity: impressumOpened ? 1 : 0 }}
            style={{ pointerEvents: impressumOpened ? "auto" : "none" }}
            className="overlay"
            id="overlay-impressum"
            onClick={() => closeImpressum()}
          >
          </motion.div>
          <motion.div
            initial={false}
            animate={{ top: impressumOpened ? "10vh" : "100vh", opacity: 1 }}
            transition={{ type: "tween", delay: impressumOpened ? .5 : 0}}
            style={{ pointerEvents: impressumOpened ? "auto" : "none" }}
            className="project-preview"
          >
            <Impressum selectedLanguage={selectedLanguage} />
          </motion.div>

          <motion.div
            initial={false}
            animate={{ opacity: dataPrivacyOpened ? 1 : 0 }}
            style={{ pointerEvents: dataPrivacyOpened ? "auto" : "none" }}
            className="overlay"
            id="overlay-data-privacy"
            onClick={() => closeDataPrivacy()}
          >
          </motion.div>
          <motion.div
            initial={false}
            animate={{ top: dataPrivacyOpened ? "10vh" : "100vh", opacity: 1 }}
            transition={{ type: "tween", delay: dataPrivacyOpened ? .5 : 0}}
            style={{ pointerEvents: dataPrivacyOpened ? "auto" : "none" }}
            className="project-preview"
          >
            <DataPrivacy selectedLanguage={selectedLanguage} />
          </motion.div>

        <header className={offset > 150 ? 'background' : 'no-background'}>
            <div onClick={() => handleClickOnNavItem("/")}>
              <Link to="/">
                <img src={logo} className="logo" alt="logo" />
              </Link>
            </div>
              <div className="navigation">
              {navigationLinks.map(link => (
                <Link to={link.url} id={"nav-" + link.id} onClick={() => handleClickOnNavItem(link.url)} className={currentUrl === link.url ? 'active' : 'not-active'} key={"link-nav-" + link.title} >
                  {dictionary.global.menu.navigation_links[selectedLanguage][link.id - 1]}
                </Link>
              ))}
              <a href="https://config.innophilia.io/" target="_blank" key={"link-nav-999"} >
                <button color="dark" className="medium">
                    { dictionary.global.menu.get_started[selectedLanguage] }
                </button>
              </a>
              <button 
                color="white" 
                className='medium language' 
                onClick={() => {setLanguage(selectedLanguage === "en" ? "de" : "en"); localStorage.setItem('language', selectedLanguage)}}>
                <img src={globe_icon} />
                { selectedLanguage.toLocaleUpperCase() }
              </button>
            </div>




            <div className="navigation-mobile">
              <button 
                color="white" 
                className='medium language' 
                onClick={() => {setLanguage(selectedLanguage === "en" ? "de" : "en"); localStorage.setItem('language', selectedLanguage);}}>
                <img src={globe_icon} />
                { selectedLanguage.toLocaleUpperCase() }
              </button>

              <button color="dark" className="medium" onClick={() => setMobileMenu(true)}>
                    <img src={menu} />
              </button>

              <motion.div
                initial={false}
                animate={{ opacity: isMobileNavigationOpen ? 1 : 0 }}
                style={{ pointerEvents: isMobileNavigationOpen ? "auto" : "none" }}
                className="content"
              >
                {navigationLinks.map(link => (
                  <Link to={link.url} id={"nav-mobile-" + link.id} onClick={() => handleClickOnNavItem(link.url)} className={currentUrl === link.url ? 'active' : 'not-active'} key={"link-nav-mobile-" + link.title} >
                    <div className="entry">
                      {dictionary.global.menu.navigation_links[selectedLanguage][link.id - 1]}
                    </div>
                  </Link>
                ))}
                   <a href="https://config.innophilia.io/" target="_blank">
                   <div className="entry close" onClick={() => setMobileMenu(false)}>
                      { dictionary.global.menu.get_started[selectedLanguage] }
                    </div>
                   </a>
                    <div className="entry close" onClick={() => setMobileMenu(false)}>
                      { dictionary.global.menu.close[selectedLanguage] }
                    </div>
              </motion.div>
            </div>
        </header>

    
      <Switch>
          <Route path="/about">
            <About selectedLanguage={selectedLanguage} />
          </Route>
          <Route path="/work">
            <Work selectedLanguage={selectedLanguage} projectsData={projectsData} />
          </Route>
          {/* <Route path="/mvp">
            <MVP />
          </Route> */}
          <Route path="/services">
            <Services selectedLanguage={selectedLanguage} />
          </Route>
          <Route path="/team">
            <Team selectedLanguage={selectedLanguage} />
          </Route>
          <Route path="/contact">
            <Contact selectedLanguage={selectedLanguage} />
          </Route>
          <Route path="/">
            <Home selectedLanguage={selectedLanguage} projectsData={projectsData} />
          </Route>
        </Switch>
        
        <div className="footer">
              <a key={"impressum"} onClick={() => openImpressum()}>
                  { dictionary.global.footer.imprint[selectedLanguage] }
              </a>
              <a key={"data-privacy"} onClick={() => openDataPrivacy()}>
              { dictionary.global.footer.privacy[selectedLanguage] }
              </a>
            </div>
      </div>
    </Router>
  );
}














function Home(props: {selectedLanguage: "de" | "en", projectsData: ProjectDetailsProps[]}) {
  const { projectsData } = props;

  const [isSelected, setIsSelected] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectDetailsProps>(
    {
        id: 0,
        title: "",
        subtitle: {
          en: "",
          de: ""
        },
        tags: [{
          title: {
            en: "",
            de: ""
          },
          list: {
            en: [],
            de: []
          }
        }],
        type: {
          en: "",
          de: ""
        },
        description: {
          en: "",
          de: ""
        },
        width: 0,
        pictures: []
      }
    );
  let selectedLanguage = props.selectedLanguage;

  TrackPageView("home", "https://www.innophilia.io/");

  function openCard(project: ProjectDetailsProps) {
    setIsSelected(true);
    setSelectedProject(project);
    document.body.style.overflow = "hidden"
  }

  function closeCard() {
    setIsSelected(false);
    document.body.style.overflow = "scroll"
  }

 function handleWorkButtonClick() {
    document.getElementById('nav-2')!.click();
  }

  function handleAboutUsButtonClick() {
    document.getElementById('nav-3')!.click();
  }

  function handleServicesButtonClick() {
    document.getElementById('nav-4')!.click();
  }

  function handleContactButtonClick() {
    document.getElementById('nav-6')!.click();
  }

  const typewriterWords = ["innovation.", "great apps.", "outstanding websites.", "simplicity."];

  const [projectImagesLoaded, setProjectImagesLoaded] = useState(0);

  return <div>
          <motion.div
            initial={false}
            animate={{ opacity: isSelected ? 1 : 0 }}
            style={{ pointerEvents: isSelected ? "auto" : "none" }}
            className="overlay"
            id="overlay"
            onClick={() => closeCard()}
          >
          </motion.div>
          
          <motion.div
            initial={false}
            animate={{ top: isSelected ? "10vh" : "100vh", opacity: 1 }}
            transition={{ type: "tween", delay: isSelected ? .5 : 0}}
            style={{ pointerEvents: isSelected ? "auto" : "none" }}
            className="project-preview"
          >
            <ProjectDetails selectedLanguage={selectedLanguage} selectedProject={selectedProject}/>
          </motion.div>
          <div className="content">
            <div className="slide one">
              <div className="hero">
                <h1>
                  { dictionary.home.hero.title[selectedLanguage] }
                  <br />
                  <span className="primary">
                  <Typewriter
                    options={{
                      strings: dictionary.home.hero.typewriter_words[selectedLanguage],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                  </span>
                </h1>
                <p>
                  { dictionary.home.hero.description[selectedLanguage] }
                </p>
                <div className="button-group">
                <button color="dark" className="medium" onClick={() => handleWorkButtonClick()}>
                  { dictionary.home.hero.cta.see_our_work[selectedLanguage] }
                </button>
                <button color="transparent" className="medium" onClick={() => handleAboutUsButtonClick()}>
                  { dictionary.home.hero.cta.about_us[selectedLanguage] }
                </button>
                </div>
              </div>
            </div>

            <div className="slide two">
              <div className="clients">
                  <p>
                    { dictionary.home.partners[selectedLanguage] }
                  </p>
                  <img src={customers} className="horizontal"/>
                  <img src={customersMobile} className="vertical"/>
                </div>
            </div>

           <Testimonials selectedLanguage={ selectedLanguage } dictionary={dictionary} />


            <div className="slide three">
              <div className="projects">
                <h1>{ dictionary.home.projects.title[selectedLanguage] }</h1>
                <p>{ dictionary.home.projects.description[selectedLanguage] }</p>

                <div className="grid">
                {projectsData.slice(0, 4).map((project, index) => (
                  <div className={"entry w-" + project.width} onClick={() => openCard(project)} key={"project-" + project.title}>
                    <motion.img 
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: projectImagesLoaded !== 4 ? 0 : 1
                      }}
                      transition={
                        ({ opacity: { delay: (0.1 * index), duration: 0.4 } })
                      }
                      onLoad={() => setProjectImagesLoaded(projectImagesLoaded +1)}
                      src={project.pictures[0]} 
                      alt="project-img"
                       className="thumbnail" />
                    <div className="description">
                      <div className="text">
                        <h4>{project.title}</h4>
                        <p>{project.type[selectedLanguage]}</p>
                      </div>
                      <img src={arrow} className="arrow" />
                    </div>
                </div>
                ))}
                </div>

                <button color="dark" className="medium" onClick={() => handleWorkButtonClick()}>
                { dictionary.home.projects.cta[selectedLanguage] }
                </button>
              </div>
            </div>

            <ProcessCards selectedLanguage={selectedLanguage} dictionary={dictionary} />

            <div className="button-group-spread">
              <button color="transparent" style={{border: "solid 2px"}} className="medium" onClick={() => handleAboutUsButtonClick()}>
                { dictionary.home.process.buttons.about[selectedLanguage] }
              </button>
              <button color="transparent" style={{border: "solid 2px"}} className="medium" onClick={() => handleServicesButtonClick()}>
                { dictionary.home.process.buttons.services[selectedLanguage] }
              </button>
              <button color="transparent" style={{border: "solid 2px"}} className="medium" onClick={() => handleWorkButtonClick()}>
                { dictionary.home.process.buttons.work[selectedLanguage] }
              </button>
            </div>

            <div className="slide five">
              <div className="hero">
                  <h1>
                  { dictionary.home.contact_cta.title[selectedLanguage] }
                    <br />
                    <span className="primary">
                      { dictionary.home.contact_cta.title_cta[selectedLanguage] }
                    </span>
                  </h1> 
                  <div className="button-group">
                  <button color="dark" className="medium" onClick={() => handleContactButtonClick()}>
                    { dictionary.home.contact_cta.button[selectedLanguage] }
                  </button>
                  </div>
                </div>
            </div>
          </div>
        </div>;
}









function Team(props: {selectedLanguage: "de" | "en"}) {
  const selectedLanguage = props.selectedLanguage;
  const [isSelected, setIsSelected] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<PositionDetailsProps>();
  const [people, setPeopleData] = useState([] as any);
  const { loading, error, data, imageCount } = useFetch('https://content.innophilia.io/api/team-images?populate=%2A');
  const [peoplePicturesLoaded, setPeoplePicturesLoaded] = useState(0);

  const [index, setIndex] = React.useState(0);
  const Slider1 = React.useRef();
  const goo = (next: any) => {
    //console.log(next);
    setIndex(next);
  };

  var settings = {
    dots: false,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,

    centerMode: false,
    //centerPadding: "70px",
    //dotsClass: "colavo-carousel-dots",
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 1,
          centerPadding: "100px",
          centerMode: true,
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          centerPadding: "50px",
          centerMode: true,
        }
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10px"
        }
      }
    ]
  };

  TrackPageView("team", "https://www.innophilia.io/team");

  function openCard(position: any) {
    setIsSelected(true);
    setSelectedPosition(position);
    document.body.style.overflow = "hidden"
  }

  function closeCard() {
    setIsSelected(false);
    document.body.style.overflow = "scroll"
  }

    /* Init team data */
    const getTeamData = useCallback(async () => {
      await initTeamMembers();
    }, [])
    
    useEffect(()=>{
      getTeamData()
          .catch(console.error);
    }, [])


  const initTeamMembers = async () => {
    let projectsArray: ProjectDetailsProps[] = [];

    // TODO: sorting needs to be changed to type
    await fetch("https://content.innophilia.io/api/team-members/?sort[0]=id&populate=%2A", {
      "method": "GET",
    })
    .then(response => response.json())
    .then(response => {
      setPeopleData(response.data)
    })
    .catch(err => {
      console.log(err);
    });
    //console.log("PEOPLE", people)
    return projectsArray;
  }

  const positions: PositionDetailsProps[] = [
    {
      title: "UX Designer (m/w/d)",
      place: dictionary.team.join.positions.ux.place[selectedLanguage],
      subtitle: dictionary.team.join.positions.ux.subtitle[selectedLanguage],
      description: dictionary.team.join.positions.ux.description[selectedLanguage]
    },
    {
      title: "UI Designer (m/w/d)",
      place: dictionary.team.join.positions.ui.place[selectedLanguage],
      subtitle: dictionary.team.join.positions.ui.subtitle[selectedLanguage],
      description: dictionary.team.join.positions.ui.description[selectedLanguage]
    },
    {
      title: "Backend Developer (m/w/d)",
      place: dictionary.team.join.positions.backend.place[selectedLanguage],
      subtitle: dictionary.team.join.positions.backend.subtitle[selectedLanguage],
      description: dictionary.team.join.positions.backend.description[selectedLanguage]
    },    
    {
      title: "Web Developer (m/w/d)",
      place: dictionary.team.join.positions.web.place[selectedLanguage],
      subtitle: dictionary.team.join.positions.web.subtitle[selectedLanguage],
      description: dictionary.team.join.positions.web.description[selectedLanguage]
    },    
    {
      title: "Frontend Developer (m/w/d)",
      place: dictionary.team.join.positions.frontend.place[selectedLanguage],
      subtitle: dictionary.team.join.positions.frontend.subtitle[selectedLanguage],
      description: dictionary.team.join.positions.frontend.description[selectedLanguage]
    },
    {
      title: "Project Manager (m/w/d)",
      place:dictionary.team.join.positions.pm.place[selectedLanguage],
      subtitle: dictionary.team.join.positions.pm.subtitle[selectedLanguage],
      description: dictionary.team.join.positions.pm.description[selectedLanguage]
    }
  ]

  function handleContactButtonClick() {
    document.getElementById('nav-6')!.click();
  }

  if (loading) return <p>Loading...</p>
  //if (error) return <p>Error :(</p>
  //console.log("count", imageCount)

  //console.log("test", data);

  return  <div>
              <motion.div
            initial={false}
            animate={{ opacity: isSelected ? 1 : 0 }}
            style={{ pointerEvents: isSelected ? "auto" : "none" }}
            className="overlay"
            id="overlay"
            onClick={() => closeCard()}
          >
          </motion.div>
          
          <motion.div
            initial={false}
            animate={{ top: isSelected ? "10vh" : "100vh", opacity: 1 }}
            transition={{ type: "tween", delay: isSelected ? .5 : 0}}
            style={{ pointerEvents: isSelected ? "auto" : "none" }}
            className="project-preview"
          >
            <PositionDetails selectedPosition={selectedPosition} selectedLanguage={selectedLanguage} dictionary={dictionary} />
          </motion.div>
          
          <div className="content">
                    <div className="slide">
                      <div className="hero">
                        <h1>
                          { dictionary.team.title[selectedLanguage][0] } <span className="primary">{ dictionary.team.title[selectedLanguage][1] }</span> { dictionary.team.title[selectedLanguage][2] }
                        </h1>
                        <p>
                          { dictionary.team.description[selectedLanguage] }
                        </p>
                        <div className="people">
                        {people.map((person: any, index: number) => (
                          <div className="person" key={index}>
                            <motion.img 
                              initial={{ opacity: 0 }}
                              animate={{
                                opacity: peoplePicturesLoaded !== people.length ? 0 : 1
                              }}
                              transition={
                                ({ opacity: { delay: (0.1 * index), duration: 0.4 } })
                              }
                              onLoad={() => setPeoplePicturesLoaded(peoplePicturesLoaded +1)}
                             src={"https://content.innophilia.io/" + person.attributes.avatar.data.attributes.url} />
                            <h5>
                              { person.attributes.name}
                            </h5>
                            <p className="position">
                              { person.attributes.position}
                            </p>
                          </div>
                        ))}
                        </div>
                                        

                      </div>
                    </div>

                    {/* Team Images Carousel */}
                  
                      <div className="slide">
                        <div className="hero">
                          <h1>
                            <span className="primary">{ dictionary.team.images.title[selectedLanguage][0] }</span> { dictionary.team.images.title[selectedLanguage][1] }
                          </h1>
                          <p>
                            { dictionary.team.images.description[selectedLanguage] }
                          </p>
                          
                          <>
                          <div className='slide-wrapper'>
                            <Slider {...settings} arrows={false} beforeChange={goo}>
                              {data.map((image: any, idx: number) => (
                                <div className="images">
                                  <img src={"https://content.innophilia.io/" + image.attributes.url} alt="Image" />
                                  
                                  
                                </div>
                              ))}
                            </Slider>
                          </div>
                          </>
                            

                        </div>
                      </div>
                    
                      
                    {/* Team Positions Hiring */}

                    <div className="slide" id="positions">
                      <div className="hero">
                        <h1>
                        { dictionary.team.join.title[selectedLanguage][0] } <span className="primary">{dictionary.team.join.title[selectedLanguage][1]}</span>
                        </h1>
                        <p>
                          { dictionary.team.join.description[selectedLanguage] }
                        </p>

                      <div className="positions">
                        {
                          positions.map(position => (
                            <div className="entry">
                            <div className="text">
                            <h4>
                              { position.title }
                            </h4>
                            <p>
                              { dictionary.team.join.positions.ux.place[selectedLanguage] }
                            </p>
                            </div>
                            <button color="dark" className="medium" onClick={() => openCard(position)}>
                              { selectedLanguage === "en" ? "Apply  ➔" : "Bewerben ➔" }
                            </button>
                          </div>
                          ))
                        }
                      </div>

                      <div className="cta-contact-us-card">
                        <h4>
                          { dictionary.team.join.contact.title[selectedLanguage][0] }<br />  { dictionary.team.join.contact.title[selectedLanguage][1] }
                        </h4>
                        <button color="dark" className="medium" onClick={() => handleContactButtonClick()}>
                        { dictionary.team.join.contact.cta[selectedLanguage] }
                        </button>
                      </div>
                    </div>
                  </div>

                  </div>
          </div>;
}

function About(props: {selectedLanguage: "de" | "en"}) {
  const selectedLanguage = props.selectedLanguage;
  TrackPageView("about", "https://www.innophilia.io/about");

  return <div className="content">
    <div className="slide">
      <div className="hero">
          <h1 className="logo-written">
            <span className="primary definition-animation left">Inno</span><span className="definition-animation right">Philia</span>
          </h1>
      <div className="innophilia-definition">
        <div className="card definition-animation left">
          <h2> In·no·va·ti·on </h2>
          <p className="pronunciation">/ɪnovaˈt͜si̯oːn,Innovatión/</p>
          <p className="description">{ dictionary.about_us.name.left[selectedLanguage] }</p>
        </div>
        <div className="card definition-animation right">
          <h2>Phi·li·a</h2>
          <p className="pronunciation">{ dictionary.about_us.name.from_greek[selectedLanguage] } φιλία (philía)</p>
          <p className="description">{ dictionary.about_us.name.right[selectedLanguage] }</p>
        </div>
      </div>
      </div>

    </div>
    <div className="slide">
<div className="hero">
  <h1>
    { dictionary.about_us.values.title_dark[selectedLanguage] }<span className="primary">{ dictionary.about_us.values.title_primary[selectedLanguage] }</span>
  </h1>
  <p>
    { dictionary.about_us.values.description[selectedLanguage] }
  </p>
</div>
</div>
<div className="values-list">
  <div className="value">
    <h1>B</h1>
    <h3>Be loyal, be wise.</h3>
    <p>
    { dictionary.about_us.values.be_loyal[selectedLanguage] }
    </p>
  </div>
  <div className="value">
    <h1>C</h1>
    <h3>Creative and self-reliant</h3>
    <p>
    { dictionary.about_us.values.creative[selectedLanguage] }
    </p>
  </div>
  <div className="value">
    <h1>F</h1>
    <h3>Focus on the goals</h3>
    <p>
    { dictionary.about_us.values.focus[selectedLanguage] }
    </p>
  </div>
  <div className="value">
    <h1>H</h1>
    <h3>Honesty wins</h3>
    <p>
    { dictionary.about_us.values.honesty[selectedLanguage] }
    </p>
  </div>
</div>
  </div>
}

function Work(props: {selectedLanguage: "en" | "de", projectsData: ProjectDetailsProps[]} ) {
  const { projectsData } = props;
  const [isSelected, setIsSelected] = useState(false);
  const [projectImagesLoaded, setProjectImagesLoaded] = useState(0);
  const [selectedProject, setSelectedProject] = useState<ProjectDetailsProps>({
    id: 0,
    title: "",
    subtitle: {
      en: "",
      de: ""
    },
    tags: [{
      title: {
        en: "",
        de: ""
      },
      list: {
        en: [],
        de: []
      }
    }],
    type: {
      en: "",
      de: ""
    },
    description: {
      en: "",
      de: ""
    },
    width: 0,
    pictures: []
  });

  let selectedLanguage = props.selectedLanguage;

  TrackPageView("work", "https://www.innophilia.io/work");

  function openCard(project: ProjectDetailsProps) {
    setIsSelected(true);
    setSelectedProject(project);
    document.body.style.overflow = "hidden"
  }

  function closeCard() {
    setIsSelected(false);
    document.body.style.overflow = "scroll"
  }

  return  <div>
          <motion.div
            initial={false}
            animate={{ opacity: isSelected ? 1 : 0 }}
            style={{ pointerEvents: isSelected ? "auto" : "none" }}
            className="overlay"
            id="overlay"
            onClick={() => closeCard()}
          >
          </motion.div>
          
          <motion.div
            initial={false}
            animate={{ top: isSelected ? "10vh" : "100vh", opacity: 1 }}
            transition={{ type: "tween", delay: isSelected ? .5 : 0}}
            style={{ pointerEvents: isSelected ? "auto" : "none" }}
            className="project-preview"
          >
            <ProjectDetails selectedProject={selectedProject} selectedLanguage={selectedLanguage} />
          </motion.div>
          <div className="content">
          <div className="slide">
            <div className="hero">
              <h1>
                {dictionary.projects.title_dark[selectedLanguage]} <span className="primary">{dictionary.projects.title_primary[selectedLanguage]} </span>
              </h1>
              <p>
                { dictionary.projects.description[selectedLanguage] }
              </p>
              <div className="projects margin-top-80 margin-bottom-80">
                <div className="grid">
                {projectsData.map((project, index) => (
                  <div className={"entry w-" + project.width} onClick={() => openCard(project)} key={"project-" + project.title}>
                    <motion.img 
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: projectImagesLoaded !== projectsData.length ? 0 : 1
                      }}
                      transition={
                        ({ opacity: { delay: (0.1 * index), duration: 0.4 } })
                      }
                      onLoad={() => setProjectImagesLoaded(projectImagesLoaded +1)}
                      src={project.pictures[0]}  
                      className="thumbnail" />
                    <div className="description">
                      <div className="text">
                        <h4>{project.title}</h4>
                        <p>{project.type[selectedLanguage]}</p>
                      </div>
                      <img src={arrow} className="arrow" />
                    </div>
                </div>
                ))}
                </div>   
              </div>         
        </div>
        </div>
      </div>
    </div>
}

function MVP() {
  function handleContactButtonClick() {
    document.getElementById('nav-6')!.click();
  }

  function handleWorkButtonClick() {
    document.getElementById('nav-2')!.click();
  }

  return <div className="content">
            <div className="slide column">
              <div className="hero">
                <h1>
                  What is a <span className="primary">MVP</span>?
                </h1>
                <p>
                A Minimum Viable Product is the preliminary but functional version of a product that can be used to gather user feedback and test demand.
                <br />This accelerates product development in terms of time and saves undesirable developments, valuable resources and thereby money.
                </p>
              </div>
              <div className="mvp-build">
                <div className="card">
                  <h4>Why MVP?</h4>
                <img src={checkmark} className="checkmark"/>Develop your idea quickly
                  <br /><img src={checkmark} className="checkmark"/> Minimize high financial risks
                  <br /><img src={checkmark} className="checkmark"/>Development together with users
                  <br /><img src={checkmark} className="checkmark"/>Reduce costs
                  <br /><img src={checkmark} className="checkmark"/>Generate revenue directly
                </div>
                <div className="card">
                  <h4>Typical flow</h4>
                  <p className="description">
                    A structured working process is key for great results.
                  </p>
                  <div className="timeline">
                    <div className="step"><p>Planning</p></div>
                    <div className="step"><p>Design</p></div>
                    <div className="step"><p>Develop</p></div>
                    <div className="step"><p>Tests</p></div>
                    <div className="step"><p>Develop</p></div>
                  </div>
                </div>
              </div>
              <button color="dark" className="medium margin-top-80" onClick={() => handleWorkButtonClick()}>
                  See all projects 
              </button>
              <div className="slide cta-contact-subpage">
              <div className="hero">
                  <h1>
                  Have a project in mind?
                    <br />
                    <span className="primary">
                      Let's work.
                    </span>
                  </h1> 
                  <div className="button-group">
                  <button color="dark" className="medium" onClick={() => handleContactButtonClick()}>
                    Get in contact
                  </button>
                  </div>
                </div>
            </div>
            </div>
          </div>
}

function Services(props: { selectedLanguage: "de" | "en" }) {
  const selectedLanguage = props.selectedLanguage;

  const [serviceTitleOnHover, setServiceTitleOnHover] = useState("");
  const [isServiceOnHover, setIsServiceOnHover] = useState(false);
  const services = [
    {
      icon: services_strategy,
      title: dictionary.services.strategy.title[selectedLanguage],
      description: dictionary.services.strategy.description[selectedLanguage]
    },
    {
      icon: services_ux,
      title: dictionary.services.design.title[selectedLanguage],
      description: dictionary.services.design.description[selectedLanguage]
    },
    {
      icon: services_development,
      title: dictionary.services.development.title[selectedLanguage],
      description: dictionary.services.development.description[selectedLanguage]
    },
    {
      icon: services_brand,
      title: dictionary.services.brand.title[selectedLanguage],
      description: dictionary.services.brand.description[selectedLanguage]
    },
    {
      icon: services_marketing,
      title: dictionary.services.marketing.title[selectedLanguage],
      description: dictionary.services.marketing.description[selectedLanguage]
    },
    {
      icon: services_ecommerce,
      title: dictionary.services.commerce.title[selectedLanguage],
      description: dictionary.services.commerce.description[selectedLanguage]
    }
  ];
  const servicesBulletp = [
    {
      icon: services_strategy,
      title: dictionary.services.strategy.title[selectedLanguage],
      description: dictionary.services.strategy.bulletpoints[selectedLanguage]
    },
    {
      icon: services_ux,
      title: dictionary.services.user_experience.title[selectedLanguage],
      description: dictionary.services.user_experience.bulletpoints[selectedLanguage]
    },
    {
      icon: services_design,
      title: dictionary.services.design.title[selectedLanguage],
      description: dictionary.services.design.bulletpoints[selectedLanguage]
    },
    {
      icon: services_development,
      title: dictionary.services.development.title[selectedLanguage],
      description: dictionary.services.development.bulletpoints[selectedLanguage]
    }
  ];

  TrackPageView("services", "https://www.innophilia.io/services");

  function handleContactButtonClick() {
    document.getElementById('nav-6')!.click();
  }

  return<>
    <div className="content services">
      <div className="slide">
        <div className="hero">
            <h1>
              { dictionary.services.title_dark[selectedLanguage] } <span className="primary">{dictionary.services.title_primary[selectedLanguage]}</span>
            </h1>
            <p>
              { dictionary.services.description[selectedLanguage] }
            </p>
        </div>
      </div>
      <div className='interactive-circle'>
        <img src={services_circle} className='bg' />
        <motion.h4
            initial={{opacity: 0}}
            animate={isServiceOnHover ? {opacity: 1} : {opacity: 0}}
            transition={{duration: 0.5}}>
            { serviceTitleOnHover }
          </motion.h4>
        {
          serviceTitleOnHover ?
          <></>
          :
          <></>
        }
        
        {services.map((service, index) => (
                  <div className={'int-c-entry' + ' i-' + index} 
                       onMouseEnter={() => {setServiceTitleOnHover(service.title); setIsServiceOnHover(true)}}
                       onMouseLeave={() => {setIsServiceOnHover(false)}}>
                    <img src={service.icon} />
                  </div>
        ))}
      </div>
      

      <Swiper
        className="swiper"
        modules={[Autoplay, Pagination]}
        pagination={{clickable: true}}
        spaceBetween={10}      
        slidesPerView={1}
        style={{paddingBottom: "40px"}}>
        {servicesBulletp.map((service, index) => (
                <SwiperSlide className="card"> 
                        <div className="entry" key={"step-" + index}>
                        <img src={service.icon} />
                        <div className="text">
                            <h4>
                            {service.title}
                            </h4>
                            <ul>
                            {service.description.map((bulletpoint, index) => (
                                    <li>
                                    {bulletpoint}
                                    </li>
                            ))}
                            </ul>
                        </div>
                        </div>
                </SwiperSlide>
            ))
        }
      </Swiper>
      <div className="grid">
          {servicesBulletp.map((service, index) => (
            <div className="entry" key={"step-" + index}>
            <img src={service.icon} />
            <div className="text">
                <h4>
                {service.title}
                </h4>
                <ul>
                {service.description.map((bulletpoint, index) => (
                        <li>
                        {bulletpoint}
                        </li>
                ))}
                </ul>
            </div>
            </div>
          ))}
          </div>
          <div className="slide">
            <div className="service-timeline">
              <h1>
                { dictionary.services.timeline.title[selectedLanguage] }
              </h1>
              <p>
                { dictionary.services.timeline.description[selectedLanguage] }
              </p>
              <img className="services-curve" src={services_curve}></img>
              <img className="services-curve-mobile" src={services_curve_mobile}></img>
          </div>
          </div>
          <div className="slide cta-contact-subpage">
            <div className="hero">
                <h1>
                { dictionary.services.contact_cta.title[selectedLanguage] }
                  <br />
                  <span className="primary">
                  { dictionary.services.contact_cta.title_cta[selectedLanguage] }
                  </span>
                </h1> 
                <div className="button-group">
                <button color="dark" className="medium" onClick={() => handleContactButtonClick()}>
                  { dictionary.services.contact_cta.button[selectedLanguage] }
                </button>
                </div>
              </div>
          </div>
    </div>
  </>;
}

function Products(props: {selectedLanguage: "de" | "en"}) {
  // TODO
}

function Contact(props: {selectedLanguage: "de" | "en"}) {
  const selectedLanguage = props.selectedLanguage;

  TrackPageView("contact", "https://www.innophilia.io/contact");

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function sendData() {
    const mail = (document.getElementById("e-mail") as HTMLInputElement).value;
    const name = (document.getElementById("name") as HTMLInputElement).value;
    const message = (document.getElementById("message") as HTMLInputElement).value;
    const correctMail = validateEmail(mail);

    if(!mail || !name || correctMail === false) {
      alert(selectedLanguage === "en" ? "Please enter a name and a valid e-mail address." : "Bitte geben Sie einen Namen und eine gültige E-Mail Adresse ein.")
      return;
    }

    if(message.length < 5) {
      alert(selectedLanguage === "en" ? "Please write us a longer message." : "Bitte schreiben Sie uns eine längere Nachricht.")
      return;
    }

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://innophilia.io/api/message/", true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({
        mail,
        name,
        message
    }));

    alert("Thank you for your message. We will get back to you soon.");
    (document.getElementById("e-mail") as HTMLInputElement).value = "";
    (document.getElementById("name") as HTMLInputElement).value = "";
    (document.getElementById("message") as HTMLInputElement).value = "";
  }

  return <div className="content">
    <div className="slide">
      <div className="hero">
          <h1>
            { dictionary.contact.title[selectedLanguage][0] } <span className="primary">{ dictionary.contact.title[selectedLanguage][1] }</span>
          </h1>
          <p>
            { dictionary.contact.description[selectedLanguage] }
          </p>
          <div className="contact-card">
            <div className="input">
              <p>
                { dictionary.contact.form.name.title[selectedLanguage] }
              </p>
              <input placeholder={dictionary.contact.form.name.placeholder[selectedLanguage]} id="name">
              
              </input>
            </div>
            <div className="input">
              <p>
                { dictionary.contact.form.mail.title[selectedLanguage] }
              </p>
              <input placeholder={dictionary.contact.form.name.placeholder[selectedLanguage]} id="e-mail">
              
              </input>
            </div>
            <div className="input textarea">
              <p>
                { dictionary.contact.form.message.title[selectedLanguage] }
              </p>
              <textarea placeholder={dictionary.contact.form.message.placeholder[selectedLanguage]} id="message">
              
              </textarea>
            </div>

            <button color="dark" className="medium" onClick={() => sendData()}>
                { dictionary.contact.form.button[selectedLanguage] }
            </button>
          </div>
        </div>
      </div>
    </div>
}

export default App;
