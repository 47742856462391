import React, { useCallback, useEffect, useState } from 'react';

function Impressum(props: {selectedLanguage: "en" | "de"}) {  
    const { selectedLanguage } = props;

    const [impressumTexts, setImpressumTexts] = useState({de: "", en: ""});
    
    const handleCloseClick = () => {
        document.getElementById('overlay-impressum')!.click();
      }

    /* Init project data */
    const getData = useCallback(async () => {
        await initImpressum();
    }, [])
    
    useEffect(()=>{
        getData()
            .catch(console.error);
    }, [])

    const initImpressum = async () => {
        let text_de: string = "";
        await fetch("https://content.innophilia.io/api/imprint?locale=de", {
            "method": "GET",
        })
        .then(response => response.json())
        .then(response => {
            text_de = response.data.attributes.text;
        })
        .catch(err => {
            console.log(err);
        });

        let text_en: string = "";
        await fetch("https://content.innophilia.io/api/imprint?locale=en", {
            "method": "GET",
        })
        .then(response => response.json())
        .then(response => {
            text_en = response.data.attributes.text;
        })
        .catch(err => {
            console.log(err);
        });

        setImpressumTexts({de: text_de, en: text_en});
        console.log(impressumTexts)
    }

    return(
        <>
            <div className="imprint-text">
                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" className="close" onClick={() => handleCloseClick()}>
                <rect width="52" height="52" rx="10.4" fill="#F5F5F5"/>
                <path d="M33.8002 18.2L18.2002 33.8" stroke="black" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.2002 18.2L33.8002 33.8" stroke="black" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {
                    
                    <div dangerouslySetInnerHTML={{ __html: "<div>" + impressumTexts[selectedLanguage] + "</div>"}} />
                }
            </div>
        </>
    );

}

export { Impressum }