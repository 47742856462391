import React, { Component, useEffect, useState } from 'react';
import { motion } from "framer-motion";

export type ProjectDetailsProps = {
  id: number,
  title: string,
  subtitle: {
    en: string,
    de: string
  },
  tags: {
    title: {
      en: string,
      de: string
    },
    list: {
      en: string[],
      de: string[]
    }
    isURL?: boolean,
    urlList?: {title: string, url: string}[]
  }[],
  type: {
    en: string,
    de: string
  },
  description: {
    en: string,
    de: string
  },
  width: number,
  pictures: string[],
  video?: string[]
}

function ProjectDetails(props: {selectedProject: ProjectDetailsProps, selectedLanguage: "de" | "en"}) {
  var { selectedProject, selectedLanguage } = props;
  const [imageLoading, setImageLoading] = useState(true);
  const [image2Loading, setImage2Loading] = useState(true);
  
  useEffect(() => {
    if(!imageLoading)
      setImageLoading(true);
      setImage2Loading(true);
  }, [selectedProject]);
  
  const imageLoaded = () => {
    setImageLoading(false);
  };

  const image2Loaded = () => {
    setImage2Loading(false);
  };

  var defaultProps = {
    id: 0,
    title: "",
    subtitle: "",
    tags: [],
    type: "",
    description: "",
    width: 40,
    pictures: []
  }
  
  const handleCloseClick = () => {
    document.getElementById('overlay')!.click();
    console.log("clicked")
  }


    return (
      <>
      <div>
                <div onClick={() => handleCloseClick()}>
                  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" className="close">
                    <rect width="52" height="52" rx="10.4" fill="#F5F5F5"/>
                    <path d="M33.8002 18.2L18.2002 33.8" stroke="black" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.2002 18.2L33.8002 33.8" stroke="black" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
 

                <h2>{ selectedProject?.title }</h2>
                <div className="details">
                  <p className="subtitle">
                    { selectedProject.subtitle[selectedLanguage] }
                  </p>
                  <div className="tags">
                  {selectedProject.tags.map(tag => (
                    <div className="column">
                    <p className="title">
                      { tag.title[selectedLanguage] }
                    </p>
                    {!tag.isURL ? tag.list[selectedLanguage].map(entry => (
                      <p>{ entry }</p>
                    )) :
                    tag.urlList!.map(entry => (
                      <p><a href={entry.url} target="_blank">{ entry.title }</a></p>
                    ))
                    }
                  </div>
                  ))}
                  </div>
                </div>

                <div className="pictures">
                      <motion.img 
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: imageLoading ? 0 : 1
                      }}
                      transition={
                        ({ opacity: { delay: 0.5, duration: 0.4 } })
                      }
                      onLoad={imageLoaded}
                      src={selectedProject?.pictures[0]} />
                      { selectedProject?.pictures[1] ? 
                        <motion.img 
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: imageLoading ? 0 : 1
                          }}
                          transition={
                            ({ opacity: { delay: 0.5, duration: 0.4 } })
                          }
                          onLoad={image2Loaded}
                          src={selectedProject?.pictures[1]} />
                        : "" }
                      { (selectedProject?.pictures.length as any) < 2 &&  selectedProject?.video ? <div className="width-50" dangerouslySetInnerHTML={{ __html: "<video autoplay='true' playsinline='true' loop src=" + selectedProject?.video[0] + " />"}} /> : "" }
                </div>

              <div dangerouslySetInnerHTML={{ __html: "<p class='description'>" + selectedProject?.description[selectedLanguage] + "</p>"}}>
              </div>
              </div>
      </>
    );
}

export { ProjectDetails };