import React from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'

function TrackPageView(documentTitle: string, href: string) {
    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
      trackPageView({
        documentTitle,
        href
      })
    }, [])
}

function TrackEvent(category: string, action: string) {
    const { trackPageView } = useMatomo()


    const { trackEvent } = useMatomo()
    trackEvent({ category, action })
}


export { TrackPageView, TrackEvent };