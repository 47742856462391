import React, { Component } from 'react';
export type PositionDetailsProps = {
    title: string,
      place: string,
      subtitle: string,
      description: string
}

function PositionDetails(data: {selectedPosition: PositionDetailsProps | undefined, selectedLanguage: any, dictionary: any}) {
  const { selectedPosition, selectedLanguage, dictionary } = data;

  const defaultProps = {
    title: "",
    place: "",
    subtitle: "",
    description: ""
  }
  
  const handleCloseClick = () => {
    document.getElementById('overlay')!.click();
  }

  return(
    <>
    <div className="positions-text">
                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" className="close" onClick={() => handleCloseClick()}>
                  <rect width="52" height="52" rx="10.4" fill="#F5F5F5"/>
                  <path d="M33.8002 18.2L18.2002 33.8" stroke="black" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.2002 18.2L33.8002 33.8" stroke="black" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <h2>{ selectedPosition?.title }</h2>

                <p className="overline">
                  { dictionary.team.join.position_details.place[selectedLanguage] }
                </p>
                <p>{ selectedPosition?.place }</p>


                <p className="overline">
                { dictionary.team.join.position_details.expectations[selectedLanguage] }
                </p>
                <p>{ selectedPosition?.subtitle }</p>

                <p className="overline">
                { dictionary.team.join.position_details.role[selectedLanguage] }
                </p>
                <div dangerouslySetInnerHTML={{ __html: "<p>" + selectedPosition?.description + "</p>"}} />

              <div className="cta-mail">
                <p>
                { dictionary.team.join.position_details.cta[selectedLanguage] }
                  <h3><a href="mailto:apply@innophilia.io">apply@innophilia.io</a></h3>
                </p>
              </div>
              </div>
      </>
  );
}

export { PositionDetails };