import React, { Component } from 'react';

import step_1 from '../assets/steps/step-1.svg';
import step_2 from '../assets/steps/step-2.svg';
import step_3 from '../assets/steps/step-3.svg';

//import process_vector from "./../assets/process/vector.svg";
//import circle_group from "./../assets/process/circle_group.svg";
import process_graph from "./../assets/process/process_graph.svg";

const process_tiles = [
    {
      id: 1,
      icon: step_1
    },
    {
      id: 2,
      icon: step_2
    },
    {
      id: 3,
      icon: step_3
    }
  ]

const buttons = ['about','services','work']
  
function ProcessCards(props: {dictionary: any, selectedLanguage: "de" | "en"}) {
  function handleButtonClick(id: number) {
    switch (id) {
      case 0: 
        handleAboutUsButtonClick();
        break;
      case 1: 
        handleServicesButtonClick();
        break;
      case 2: 
        handleWorkButtonClick();
      break;
    }
  }
  function handleWorkButtonClick() {
    document.getElementById('nav-2')!.click();
  }

  function handleAboutUsButtonClick() {
    document.getElementById('nav-3')!.click();
  }

  function handleServicesButtonClick() {
    document.getElementById('nav-4')!.click();
  }

  return(
    <>
    <div className="slide four">
          <div className="process">
          <div className="title">
              <h1>{ props.dictionary.home.process.title[props.selectedLanguage] }</h1>
              <p>{ props.dictionary.home.process.description[props.selectedLanguage] }</p>
          </div>
          <div className="grid">
          {process_tiles.map(tile => (
              <div className="entry" key={"tile-" + tile.id}>

              <div className="text">
                  <h4>
                    { props.dictionary.home.process.tiles[tile.id - 1].title[props.selectedLanguage] }
                  </h4>
                  <p>
                    { props.dictionary.home.process.tiles[tile.id - 1].description[props.selectedLanguage] }
                  </p>
                  
              </div>

              <div className="button-process-mobile">
                <button color="transparent" style={{border: "solid 2px"}} className="medium" onClick={() => handleButtonClick(tile.id - 1)}
                >
                  { props.dictionary.home.process.buttons[buttons[tile.id - 1]][props.selectedLanguage]}
                </button>
              </div>

              </div>

          ))}
            <div className="process-graphic">
              <img src={process_graph} width="90%" alt="dotted line"/>
            </div>
          </div>
          </div>
      </div>
    </>
  )
}

export {ProcessCards};