import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"

import wladimir from './../assets/testimonials/wladimir.png';
import daniel from './../assets/testimonials/daniel.png';
import towa from './../assets/testimonials/towa.png';
import udo from './../assets/testimonials/udo.png';
import jeanine from './../assets/testimonials/jeanine.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Pagination } from "swiper";
import { stringify } from 'querystring';

export type TestimonialsData = {
    name: string, 
    image: string, 
    review: {
        en: string,
        de: string
    }, 
    position: {
        en: string,
        de: string
    }
};

function Testimonials(props: { dictionary: any, selectedLanguage: "de" | "en" }) {
    const { dictionary, selectedLanguage } = props;

    const [imagesLoaded, setImagesLoaded] = useState(0);
    
    const [width, setWidth] = useState<number>(window.innerWidth);

    const [testimonialsData, setTestimonialsData] = useState([] as TestimonialsData[]);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }


    const getData = useCallback(async () => {
        await initTestimonials();
    }, []);

    useEffect(()=>{
        getData()
            .catch(console.error);
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const initTestimonials = async () => {
        let testimonialsArray: TestimonialsData[] = [];

        await fetch("https://content.innophilia.io/api/testimonials/?locale=all&populate=%2A", {
          "method": "GET",
        })
        .then(response => response.json())
        .then(response => {
            testimonialsArray = createTestimonialsArray(response.data);
            console.warn("GOT TESTIMONIALS ARRAY", response.data)
        })
        .catch(err => {
          console.log(err);
        });
    
        return testimonialsArray;
    }

   const createTestimonialsArray = (data: any[]) => {
    const testimonials: TestimonialsData[] = [];

    data.forEach((testimonial) => {
      testimonial = testimonial.attributes;

      const isExistingAlready = testimonials.filter(testimonialFromArray => testimonialFromArray.name === testimonial.name).length > 0;

      if(isExistingAlready)
        return;
      

      const image = "https://content.innophilia.io/" + testimonial.picture.data.attributes.url;

      testimonials.push(
        {
            name: testimonial.name,
            image,
            review: getAttributeForObject(data, testimonial.name, 'review'),
            position: getAttributeForObject(data, testimonial.name, 'position')
        }
      );
    });
    console.log("!", testimonials);
    setTestimonialsData(testimonials);
    return testimonials;
  }

  const getAttributeForObject = (data: any[], name: string, key: string) => {
    let response: any = {};

    const testimonials = data.filter(testimonial => testimonial.attributes.name === name);
    
    testimonials.map(testimonial => {
      response[testimonial.attributes.locale] = testimonial.attributes[key];
    });

    return response;
  }
    
    const isMobile = width <= 768;
    
    return(
        <>
            <div className="slide five">
                <div className="feedback">
                  <h1>{ dictionary.home.testimonials.title[selectedLanguage] }</h1>
                  <p>{ dictionary.home.testimonials.description[selectedLanguage] }</p>
                  <div className='testimonails'>
                  <Swiper 
                    modules={[Autoplay, Pagination]}
                    // autoplay={{delay: 1000}}
                    pagination={{clickable: true}}
                    spaceBetween={10}      
                    slidesPerView={isMobile ? 1 : (testimonialsData.length > 3 ? 3 : 3)}
                    style={{paddingBottom: "40px"}}>
                      {/* <div ref={constraintsRef}> */}
                      {/* <motion.div  className="flex"> */}
                    {/* <motion.div drag="x" dragConstraints={constraintsRef} /> */}
                    {
                        testimonialsData.map((testimonial, index) => (
                            <SwiperSlide> 
                                <motion.div className='card' key={index}   whileHover={{
                                    scale: 1.03,
                                    transition: { duration: .2, type: "tween" },
                                }}>
                                <motion.img 
                                initial={{ opacity: 0 }}
                                animate={{
                                  opacity: imagesLoaded !== testimonialsData.length ? 0 : 1
                                }}
                                transition={
                                  ({ opacity: { delay: (0.1 * index), duration: 0.4 } })
                                }
                                onLoad={() => setImagesLoaded(imagesLoaded +1)}
                                 src={ testimonial.image } />
                                    <p className='text'>
                                    {testimonial.review[selectedLanguage]}
                                    </p>
            
                                    <p className='author'>
                                    { testimonial.name }
                                    </p>
                                    <p className='position'>
                                    { testimonial.position[selectedLanguage] }
                                    </p>
                                </motion.div>
                            </SwiperSlide>
                        ))
                    }
                        </Swiper>
                                              {/* </motion.div> */}
                      {/* </div> */}

                  </div>
                </div>
            </div>
        </>
    )
}

export { Testimonials };