function CookieBanner(props: { selectedLanguage: "de" | "en", dictionary: any, onAccept: any, onDeny: any}) {
    const { selectedLanguage, dictionary, onAccept, onDeny } = props;
    return(
        <>
            <div className="cookie-banner">
                <h2>
                    { dictionary.cookies.header[selectedLanguage] }
                </h2>
                <p className="text">
                    { dictionary.cookies.text[selectedLanguage] }
                </p>
                <div className="options">
                    <a href="/privacy">
                        { dictionary.cookies.learn_more[selectedLanguage] }
                    </a>
                    <div className="buttons">
                    <button onClick={onDeny}>
                        { dictionary.cookies.deny[selectedLanguage] }
                    </button>
                    <button color="dark" onClick={onAccept}>
                        { dictionary.cookies.accept[selectedLanguage] }
                    </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export { CookieBanner };