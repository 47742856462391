import { useEffect, useState } from 'react';


const useFetch = (url: any) => {
    const [data, setData] = useState<Array<any>>([ ]);
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [imageCount, setCount] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)

            try {
                /*const res = await fetch(url, {
                    "method": "GET",
                  })*/
                const res = await fetch(url)
                const json = await res.json()

                setData(json.data[0].attributes.images.data)
                setCount(json.data[0].attributes.images.data.length)
                setLoading(false)

            } catch (error: any) {
                setError(error)
                setLoading(false)

            }

        }

        fetchData()
    }, [url])


    return { loading, error, data, imageCount }
}

export default useFetch