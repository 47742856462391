import { ProjectDetailsProps } from "../ProjectDetails"

export class Projects {

  projects: ProjectDetailsProps[] = [];

  constructor() {
 
  }

  static async initProjects() {
    let projectsArray: ProjectDetailsProps[] = [];

    await fetch("https://content.innophilia.io/api/projects/?locale=all&populate=%2A", {
      "method": "GET",
    })
    .then(response => response.json())
    .then(response => {
       projectsArray = Projects.createProjectsArray(response.data);
       //console.warn("GOT PROJECTS ARRAY", projectsArray)
    })
    .catch(err => {
      console.log(err);
    });

    return projectsArray;
  }

  static createProjectsArray(data: any[]) {
    const projects: ProjectDetailsProps[] = [];

    data.forEach((project, index) => {
      const id = project.id;
      project = project.attributes;

      const isExistingAlready = projects.filter(projectFromArray => projectFromArray.title === project.title).length > 0;

      if(isExistingAlready)
        return;
      

      const pictures = ["https://content.innophilia.io/" + project.pictures.data[0].attributes.url];
      const video = project.videos.data ? ["https://content.innophilia.io/" + project.videos.data[0].attributes.url] : undefined;

      if(!video) {
        pictures.push(project.pictures.data.length > 1 ? "https://content.innophilia.io/" + project.pictures.data[1].attributes.url : "");
      }

      //console.log(video)

      projects.push(
        {
          id,
          title: project.title,
          subtitle: Projects.getAttributeForProjectObject(data, project.title, 'subtitle'),
          description: Projects.getAttributeForProjectObject(data, project.title, 'description'),
          tags: [
            {
              title: {
                en: "Services",
                de: "Leistungen"
              },
              list: Projects.getServicesAttributeForProjectObject(data, project.title)
            },
            {
              title: {
                en: "Outcome",
                de: "Ergebnis"
              },
              list: {
                en: [],
                de: []
              },
              isURL: true,
              urlList: Projects.getOutcomeAttributeForProjectObject(data, project.title)
            }
          ],
          type: Projects.getTypeAttributeForProjectObject(data, project.title),
          width: ((projects.length + 1) % 4) === 0 || ((projects.length + 1) % 4) === 1 ? 60 : 40,
          pictures,
          video,

        }
      );
    });
    //console.log(projects);
    return projects;
  }

  static getBase64Image(url: string) {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      //console.log(dataURL)
    }
    img.src = url
  }

  static getAttributeForProjectObject(data: any[], title: string, key: string) {
    let response: any = {};

    const projects = data.filter(project => project.attributes.title === title);
    
    projects.map(project => {
      response[project.attributes.locale] = project.attributes[key];
    });

    return response;
  }

  static getTypeAttributeForProjectObject(data: any[], title: string) {
    let response: any = {};

    const projects = data.filter(project => project.attributes.title === title);
    
    projects.map(project => {
      response[project.attributes.locale] = project.attributes.project_type.data.attributes.type;
    });

    return response;
  }

  static getServicesAttributeForProjectObject(data: any[], title: string) {
    let response: any = {
      "en": [],
      "de": []
    };

    const projects = data.filter(project => project.attributes.title === title);
  
    projects.map(project => {
      project.attributes.project_services.data.forEach((service: any) => {
        response[project.attributes.locale].push(service.attributes.type)
      });
    });

    return response;
  }

  static getOutcomeAttributeForProjectObject(data: any[], title: string) {
    let response: any = [];

    const projects = data.filter(project => project.attributes.title === title);
    const object = projects[0].attributes.project_outcome;

    Object.keys(object).forEach(function(key){
        switch (key) {
          case "app_store":
              response.push({title: "App Store", url: object[key].url});
            break;
          case "play_store":
              response.push({title: "Google Play", url: object[key].url});
            break;
          case "web":
              response.push({title: "Landing Page", url: object[key].url});
            break;
          case "mobile_app":
              response.push({title: "Mobile App", url: object[key].url});
            break;
          default:
            break;
        }
    });

    return response;

  }

}
